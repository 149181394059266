import {
	Button,
	FormControl,
	Box,
	Input,
	Text,
	useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { validate } from "email-validator";
import jsonp from "jsonp";

const convertListFields = (fields) => {
	let queryParams = "";
	for (const field in fields) {
		if (Object.prototype.hasOwnProperty.call(fields, field)) {
			// If this is a list group, not user field then keep lowercase, as per MC reqs
			// https://github.com/benjaminhoffman/gatsby-plugin-mailchimp/blob/master/README.md#groups
			const fieldTransformed =
				field.substring(0, 6) === "group["
					? field
					: field.toUpperCase();
			queryParams = queryParams.concat(
				`&${fieldTransformed}=${fields[field]}`
			);
		}
	}
	return queryParams;
};
const subscribeEmailToMailchimp = ({ url, timeout }) =>
	new Promise((resolve, reject) =>
		jsonp(url, { param: "c", timeout }, (err, data) => {
			if (err) reject(err);
			if (data) resolve(data);
		})
	);

const addToMailchimp = function addToMailchimp(
	email,
	fields,
	endpointOverride
) {
	const isEmailValid = validate(email);
	const emailEncoded = encodeURIComponent(email);
	if (!isEmailValid) {
		return Promise.resolve({
			result: "error",
			msg: "The email you entered is not valid.",
		});
	}

	let endpoint =
		"https://tokentus.us9.list-manage.com/subscribe/post?u=69903cde85f6c6502e66aa5d6&amp;id=f12fadbd66&amp;f_id=003811e1f0";
	const timeout = 3500;
	if (arguments.length < 3 && typeof fields === "string") {
		endpoint = fields;
	} else if (typeof endpointOverride === "string") {
		endpoint = endpointOverride;
	}

	endpoint = endpoint.replace(/\/post/g, "/post-json");
	const queryParams = `&EMAIL=${emailEncoded}${convertListFields(fields)}`;
	const url = `${endpoint}${queryParams}`;

	return subscribeEmailToMailchimp({ url, timeout });
};

const NewsletterForm = () => {
	const toast = useToast();
	const [state, setState] = useState({
		fname: null,
		lname: null,
		email: null,
		tags: null,
	});
	const [stateStatusToast, setStateStatusToast] = useState(null);
	useEffect(() => {
		console.log("HELLO:" + stateStatusToast);
	}, [stateStatusToast]);
	const _handleNameChange = (e) => {
		setState((state) => ({
			...state,
			LNAME: e.target.value,
		}));
		console.log(state);
	};
	const _handleFNameChange = (e) => {
		setState((state) => ({
			...state,
			FNAME: e.target.value,
		}));
		console.log(state);
	};
	const _handleEMailChange = (e) => {
		setState((state) => ({
			...state,
			email: e.target.value,
		}));
		console.log(state);
	};
	const _handleSubmit = (e) => {
		e.preventDefault();
		console.log("submit", state);
		addToMailchimp(
			state.email,
			state,
			"https://tokentus.us9.list-manage.com/subscribe/post?u=69903cde85f6c6502e66aa5d6&amp;id=f12fadbd66&amp;f_id=003811e1f0"
		)
			.then(({ msg, result }) => {
				console.log("msg", `${result}: ${msg}`);

				if (result !== "success") {
					throw msg;
				}

				toast({
					title: "Subscription started",
					description:
						"We have send you a confirmation email to confirm your subscription.",
					status: "success",
					duration: 9000,
					isClosable: true,
				});
			})
			.catch((err) => {
				console.log("err", err);

				toast({
					title: "Error",
					description: err,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			});
	};

	return (
		<form onSubmit={_handleSubmit}>
			<FormControl p={12} bg="brand.medlightgray" mt={12} mx={"auto"}>
				<Text
					letterSpacing={"normal"}
					fontWeight="bold"
					color="brand.meddarkgray"
					mb={0}>
					Erhalten Sie unser Investor Relations Mailing.
				</Text>
				<Text letterSpacing={"normal"} color="brand.med2gray" mb={6}>
					Das Investor Relations Mailing ist in deutscher Sprache.
				</Text>

				<Input
					onChange={_handleFNameChange}
					variant="newsletterInput"
					h="53px"
					w="49%"
					py={4}
					mr={"2%"}
					bg="brand.gray"
					placeholder="Vorname"
					_placeholder={{
						color: "brand.medgray",
					}}
					mb={4}
				/>
				<Input
					onChange={_handleNameChange}
					variant="newsletterInput"
					h="53px"
					w="49%"
					py={4}
					bg="brand.gray"
					placeholder="Nachname"
					_placeholder={{
						color: "brand.medgray",
					}}
					mb={4}
				/>
				<Input
					onChange={_handleEMailChange}
					variant="newsletterInput"
					h="53px"
					w="100%"
					py={4}
					bg="brand.gray"
					placeholder="email"
					_placeholder={{
						color: "brand.medgray",
					}}
				/>
				<Button
					type="submit"
					variant="solid"
					bg="brand.blue"
					color="white"
					mt={8}>
					Sign up
				</Button>
			</FormControl>
		</form>
	);
};

export default NewsletterForm;
