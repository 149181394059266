import {
	Box,
	Container,
	Button,
	Heading,
	useColorMode,
	Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink";
import { Helmet } from "react-helmet";
import ImgTextModule from "../components/ImgTextModule";
import IntroComponent2colMonochrome from "../components/IntroComponent2colMonochrome";
import NewsletterForm from "../components/NewsletterForm";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import "../styles-privacy.css";
export default function Privacy() {
	const { setColorMode } = useColorMode();

	useEffect(() => {
		setColorMode("light");
	});
	return (
		<>
			<Helmet>
				<title>tokentus | Investor Relations Mailing</title>
			</Helmet>
			<ImgTextModule
				src="InvestorMailing.mp4"
				delay={0.3}
				duration={0.5}
				threshold={0.3}
				initialY={10}
				initialX={10}
				video={true}
				h="55vh"
				mediaOpacity={0.9}
				bgColor="brand.black">
				<Container variant="layoutContainer" mt={8}>
					<Box p={{ base: 0, lg: "8" }} py={8}>
						<FadeInAnimation
							threshold={0.4}
							delay={0.4}
							duration={0.7}
							initialX={0}
							initialY={-10}>
							<Text
								color="brand.white"
								fontWeight="normal"
								fontSize={{ base: "5xl", lg: "6xl" }}>
								Investor Relations Mailing.
							</Text>
						</FadeInAnimation>
					</Box>
				</Container>
			</ImgTextModule>
			<Box mt={12}>
				<IntroComponent2colMonochrome
					version="thirds"
					headline="Gut informiert sein. "
					text1="Aktionäre der tokentus, aber auch solche, die ganz allgemein einen Markt-Überblick haben wollen, kommen gleichermaßen auf ihre Kosten."
					text2="Das Investor Relations Mailing fasst über einen Zeitraum von 2 Wochen in deutscher Sprache alle relevanten Informationen rund um tokentus zusammen und informiert darüber hinaus auch ganz allgemein über Bewegungen und Entwicklungen im Blockchain- und Krypto-Markt."
				/>
			</Box>
			<Container
				variant={"layoutContainer"}
				px={{ base: 0, lg: "8" }}
				w={{ base: "100%", lg: "100%" }}>
				<Box mb={12}>
					<FadeInAnimation
						threshold={0.4}
						delay={0.2}
						duration={1}
						initialX={0}
						initialY={11}>
						<NewsletterForm />
					</FadeInAnimation>
				</Box>
			</Container>
			<Container
				variant="layoutContainer"
				mt={32}
				mb={0.5}
				display="grid"
				gridGap={0.5}
				gridTemplateColumns={{
					lg: "repeat(2, 1fr)",
					md: "repeat(2, 1fr)",
					sm: "repeat(1, 1fr)",
				}}>
				<ImgTextModule
					minH="450px"
					w="100%"
					src={"lac4.mp4"}
					delay={0.9}
					duration={1}
					threshold={0.4}
					initialY={0}
					initialX={0}
					video={true}
					mediaOpacity={1}
					bgColor="black">
					<FadeInAnimation threshold={0.4} duration={1}>
						<Box p={8}>
							<FadeInAnimation
								threshold={0.4}
								delay={0.4}
								duration={0.7}
								initialX={0}
								initialY={-10}>
								<Text color="white" fontSize="4xl">
									Investor Relations.
								</Text>
							</FadeInAnimation>

							<FadeInAnimation
								threshold={0.4}
								delay={1}
								duration={0.7}
								initialX={0}
								initialY={10}>
								<Text color="brand.green" fontSize="md">
									Information at your fingertips.
								</Text>
							</FadeInAnimation>
							<RouterLink
								isExternal={false}
								link={"/investorrelations"}>
								<Button
									mt={4}
									variant="solid"
									bg="brand.green"
									color="brand.darkgray">
									Investor Relations
								</Button>
							</RouterLink>
						</Box>
					</FadeInAnimation>
				</ImgTextModule>

				<ImgTextModule
					minH="450px"
					w="100%"
					src={"header-master.mp4"}
					delay={0.9}
					duration={1}
					threshold={0.4}
					initialY={0}
					initialX={0}
					video={true}
					mediaOpacity={1}
					bgColor="black">
					<FadeInAnimation threshold={0.4} duration={1}>
						<Box p={8}>
							<FadeInAnimation
								threshold={0.4}
								delay={0.4}
								duration={0.7}
								initialX={0}
								initialY={-10}>
								<Text color="white" fontSize="4xl">
									Experts.
								</Text>
							</FadeInAnimation>

							<FadeInAnimation
								threshold={0.4}
								delay={1}
								duration={0.7}
								initialX={0}
								initialY={10}>
								<Text color="brand.green" fontSize="md">
									From Seniority to Innovation.
								</Text>
							</FadeInAnimation>
							<RouterLink isExternal={false} link={"/team"}>
								<Button
									mt={4}
									variant="solid"
									bg="brand.green"
									color="brand.darkgray">
									Meet the team
								</Button>
							</RouterLink>
						</Box>
					</FadeInAnimation>
				</ImgTextModule>
			</Container>
		</>
	);
}
